<template>
  <div>
    <div class="card card-custom mt-5">
      <div class="card-body">
        <!-- Filter -->
        <div>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row">
              <div class="col-12 mt-10">
                <table class="table table-bordered">

                  <tbody>
                  <tr>
                    <td v-if="$can('report_stores.report_stocks') && isSystemUser">
                      <span>{{ $t('store_reports.inventory_stocktaking_sheet') }}</span>
                      <span class="float-right ml-2">
                                        <router-link :to="{name: 'stocks-report.list'}" target="_blank">
                                            <span class="float-right">{{ $t('view') }}</span>
                                        </router-link>
                                     </span>
                    </td>
                    <td v-if="$can('report_stores.daily_brief_report_payments') && isSystemUser">
                      <span>{{ $t('store_reports.daily_payments') }}</span>
                      <router-link :to="{name: 'daily-brief-report-payments.list'}" target="_blank">
                        <span class="float-right ml-2">| {{ $t('details') }}</span>
                      </router-link>
                      <router-link :to="{name: 'daily-brief-report-payments.list'}" target="_blank">
                        <span class="float-right">{{ $t('summery') }}</span>
                      </router-link>
                    </td>
                  </tr>
                  <tr>
                    <td v-if="$can('report_stores.report_inventory_transaction')">
                      <span>{{ $t('store_reports.inventory_transactions') }}</span>
                      <router-link :to="{name: 'inventory-transaction-report.list'}" target="_blank">
                        <span class="float-right ml-2">{{ $t('view') }}</span>
                      </router-link>
                    </td>
                    <td v-if="$can('report_stores.daily_brief_report_payments') && isSystemUser">
                      <span>{{ $t('store_reports.weekly_payments') }}</span>
                      <router-link :to="{name: 'daily-brief-report-payments.list',query:{ filter_by: 'weekly'}}" target="_blank">
                        <span class="float-right ml-2">| {{ $t('details') }}</span>
                      </router-link>
                      <router-link :to="{name: 'daily-brief-report-payments.list',query:{ filter_by: 'weekly'}}" target="_blank">
                        <span class="float-right">{{ $t('summery') }}</span>
                      </router-link>
                    </td>
                  </tr>
                  <tr>
                    <td v-if="$can('report_stores.report_inventory_transaction_details')">
                      <span>{{ $t('store_reports.inventory_detailed_transactions') }}</span>
                      <router-link :to="{name: 'inventory-transaction-details.list'}" target="_blank">
                        <span class="float-right ml-2">{{ $t('view') }}</span>
                      </router-link>
                    </td>
                    <td colspan="2" v-if="$can('report_stores.daily_brief_report_payments') && isSystemUser">
                      <!-- :to="{name: 'inventory-transaction-details.list', query:{ filter_by: 'monthly'} }" -->
                      <span>{{ $t('store_reports.monthly_payments_details') }}</span>
                      <router-link :to="{name: 'daily-brief-report-payments.list',query:{ filter_by: 'monthly'}}" target="_blank">
                        <span class="float-right ml-2">{{ $t('view') }}</span>
                      </router-link>
                    </td>
                  </tr>
                  <tr>
                    <td v-if="$can('report_stores.report_estimated_inventory')">
                      <span>{{ $t('store_reports.inventory_value') }}</span>
                      <router-link :to="{name: 'estimated-inventory.list'}" target="_blank">
                        <span class="float-right ml-2">{{ $t('view') }}</span>
                      </router-link>
                    </td>
                    <td v-if="$can('report_stores.daily_brief_report_payments') && isSystemUser">
                      <span>{{ $t('store_reports.yearly_payments_details') }}</span>
                      <router-link :to="{name: 'daily-brief-report-payments.list',query:{ filter_by: 'yearly'}}" target="_blank">
                        <span class="float-right">{{ $t('summery') }}</span>
                      </router-link>
                    </td>
                  </tr>
                  <tr>
                    <td v-if="$can('report_stores.report_summary_stock_balance') && isSystemUser">
                      <span>{{ $t('store_reports.summary_of_stock_balance') }}</span>
                      <router-link :to="{name: 'summary-stock-balance.list'}" target="_blank">
                        <span class="float-right ml-2">{{ $t('view') }}</span>
                      </router-link>
                    </td>
                    <td v-if="$can('report_stores.report_product_average_cost') && isSystemUser">
                      <span>{{ $t('store_reports.product_average_cost') }}</span>
                      <router-link :to="{name: 'product-average-cost.list'}" target="_blank">
                        <span class="float-right ml-2">{{ $t('view') }}</span>
                      </router-link>
                    </td>
                  </tr>
                  <tr>
                    <td v-if="$can('report_stores.report_daily_product_sales')">
                      <span>{{ $t('store_reports.product_sales_Periodic_Reports') }}</span>
                      <router-link :to="{name: 'daily-product-sales.list'}" target="_blank">
                        <span class="float-right ml-2">{{ $t('view') }}</span>
                      </router-link>
                    </td>
                  </tr>
                  <tr>

                    <td v-if="$can('report_stores.detailed_stock_transactions_per_product')">
                      <span>{{ $t('store_reports.detailed_stock_transactions_per_product') }}</span>
                      <router-link :to="{name: 'detailed-stock-transactions-per-product.list'}" target="_blank">
                        <span class="float-right ml-2">{{ $t('view') }}</span>
                      </router-link>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters} from "vuex";

export default {
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.store_reports")}]);
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    isSystemUser: function () {
      return this.currentUserPersonalInfo.is_system_user
    },
  },
}
</script>
<style scoped>
td {
  padding: 1rem;
}
</style>